<template>
  <div class="result">
    <div class="topBg" :style="rate >= 90 ? `background: url(${img2}) no-repeat` : `background: url(${img1}) no-repeat`">
      <van-icon class="iconback" name="arrow-left" @click="gohome" color="#fff" size="20" />
      <van-circle class="Ratemore" v-model="currentRate" :rate="rate * 65/100" color="#fff" :stroke-width="80" :speed="100"/>
      <p class="qubi">{{rate}}</p>
      <p class="rightnoe">{{rate >= 90 ? '及格' : '不及格'}}</p>
      <div class="text">荣获<span>{{rate >= 90 ? '新晋司机' : '马路杀手'}}</span>称号</div>
    </div>
    <div class="box" @click="look(1)">
      <div class="lfet fl">
        <van-icon name="todo-list" color="#0775F6" />
        <p>查看错题</p>
      </div>
      <van-icon name="arrow" />
    </div>
    <div class="box" @click="look(2)">
      <div class="lfet fl">
        <van-icon name="comment" color="#0775F6" />
        <p>回顾试卷</p>
      </div>
      <van-icon name="arrow" />
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      car: 1,
      img1: require("../assets/image/resultbg.png"),
      img2: require("../assets/image/resultbg1.png"),
      currentRate: 50,
      rate: 90,
    };
  },
  computed: {},
  watch: {},
  created() {
    this.rate = this.$route.query.id;
  },
  mounted() {},
  methods: {
    gohome() {
      this.$router.push('/')
    },
    look(e) {
      if (e === 1) {
        this.$router.push({
          path: '/examination',
          query: {
            id: 'error'
          }
        })
      } else {
        this.$router.push({
          path: '/examination',
          query: {
            id: 8
          }
        })
      }
    }
  },
};
</script>

<style scoped lang="scss">
.result {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .topBg {
    width: 100%;
    height: 311px;
    position: relative;
    background: url("../assets/image/resultbg1.png");
    background-size: 100% 100% !important;
    .iconback{
      position: absolute;
      left: 20px;
      top: 20px;
    }
    .Ratemore{
      width: 116px;
      height: 116px;
      position: absolute;
      top: 70px;
      left: 129.5px;
      transform: rotate(-115deg);
      ::v-deep.van-circle__layer{
        stroke: none;
      }
    }
    .qubi{
      font-size: 32px;
      font-family: SourceHanSansSC-Medium, SourceHanSansSC;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 46px;
      position: absolute;
      top: 99px;
      text-align: center;
      width: 100%;
    }
    .rightnoe{
      width: 100%;
      position: absolute;
      top: 149px;
      text-align: center;
      font-size: 14px;
      font-family: SourceHanSansSC-Regular, SourceHanSansSC;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 20px;
    }
    .earthcle {
      width: 120px;
      height: 120px;
      border: 1px solid #ddd;
      background: #fff;
      position: absolute;
      top: 78px;
      left: 50%;
      transform: translateX(-60px);
    }
    .text{
      width: 100%;
      position: absolute;
      top: 205px;
      padding-top: 35px;
      text-align: center;
      font-size: 16px;
      font-family: SourceHanSansSC-Regular, SourceHanSansSC;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 24px;
      z-index: 1;
      span{
        padding: 0 17px;
        font-size: 32px;
        font-family: SourceHanSansSC-Medium, SourceHanSansSC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 46px;
      }
    }
  }
  .box{
    width: 297px;
    height: 50px;
    margin-top: 27px;
    background: #FFFFFF;
    box-shadow: 0px 2px 4px 0px rgba(160, 160, 160, 0.4);
    border-radius: 4px;
    padding: 0 27px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .fl{
      display: flex;
      align-items: center;
    }
    i{
      font-size: 18px;
    }
    p{
      padding-left: 7px;
    }
  }
}
</style>
